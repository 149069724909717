import React, {useEffect, useState} from "react";
import _ from "lodash";
import {Panel, PanelGroup} from "react-bootstrap"
import vintageAxios from "services/api";
import {useHistory, useLocation} from "react-router"
import QueryParams from "utils/QueryParams"
import arrayify from "services/browser-history/arrayify"
import {
  QUERY_PARAM_CATEGORIES,
  QUERY_PARAM_GAMING_PLATFORM
} from "modules/store/scenes/ProductsList/constants/query-params"
import "./styles/SidebarPlatforms.scss"

export default function SidebarPlatforms() {
  const location = useLocation()
  const history = useHistory()
  const queryParams = new QueryParams(location.search)
  const [loading, setLoading] = useState(null)
  const [platforms, setPlatforms] = useState(null)

  useEffect(() => {
    if (!loading && !platforms)
      fetchPlatforms()
  }, [loading, platforms])

  function fetchPlatforms() {
    setLoading(true)
    const promise = vintageAxios.get("/products/platforms/")
    promise.then(response => {
      setLoading(false)
      setPlatforms(response.data.results)
    })
    promise.catch(error => {
    })
  }

  /**
   * Handle onChange event of the checkboxes
   * @param event {Object}
   */
  function handleOnChange(event) {
    const {value} = event.target;
    let values = arrayify(queryParams.find(QUERY_PARAM_GAMING_PLATFORM));
    values = _.xor(values, [value]);  // toggle the clicked one
    queryParams.update({
      [QUERY_PARAM_GAMING_PLATFORM]: values
    });
    history.push({
      pathname: location.pathname,
      search: queryParams.asSearchString
    });
  }

  // TODO store that 26 in a constant
  if (!arrayify(queryParams.find(QUERY_PARAM_CATEGORIES)).includes("26"))
    return null

  return (
      <React.Fragment>
        <div>
          <PanelGroup
              id="new_or_used"
              defaultActiveKey="1" accordion>
            <Panel
                eventKey="1"
                header={<h6>Platform<i className="fa fa-caret-up"/></h6>}>
              <div
                  className="platforms-checkboxes"
                  style={{
                height: "300px",
                overflowY: "scroll"
              }}>
                {
                  platforms && platforms.map(platform => (
                      <div
                          key={platform.id}>
                        <div className="checkbox">
                          <label htmlFor={platform.id}>
                            <input
                                type="checkbox"
                                id={platform.id}
                                onChange={handleOnChange}
                                name="productStatus"
                                value={platform.id}
                                checked={arrayify(queryParams.find(QUERY_PARAM_GAMING_PLATFORM)).includes(platform.id.toString())}/>
                            <span className="text">&nbsp;&nbsp;{platform.name}</span>
                          </label>
                        </div>
                      </div>
                  ))
                }
              </div>
            </Panel>
          </PanelGroup>
        </div>
      </React.Fragment>
  )
}