import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/array';
import 'core-js/es7/array';
import React from 'react';
import ReactDOM from 'react-dom';
import {reducer as formReducer} from 'redux-form';
import ReduxToastr, {reducer as toastrReducer} from 'react-redux-toastr';
import thunkMiddleware from 'redux-thunk';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/fonts/icomoon/style.css';
import './assets/fonts/icomoon-2/style.css';
import './assets/fonts/icomoon-3/style.css';
import './assets/styles/VintageStock.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "font-awesome/css/font-awesome.css";
import {unregister} from 'registerServiceWorker';
import {Provider} from 'react-redux';
import {BrowserRouter, Redirect, Route, Switch, Router} from 'react-router-dom';
import {applyMiddleware, combineReducers, createStore} from 'redux';
import Home from './modules/Home';
import CheckoutSuccess from "./modules/store/scenes/CheckoutSuccess";
import NotFound from "./modules/ServerErrors/scenes/NotFound";
import About from "./modules/about";
import {auth} from "modules/auth/reducer";
import {account} from "modules/MyAccount/reducer";
import {contactUs} from "modules/contact/reducer";
import {store} from "modules/store/reducer";
import {newsletter} from "components/NewsletterSubscribe/reducer";
import LoginContainer from "./modules/auth/scenes/Login/container";
import LogoutContainer from "./modules/auth/scenes/Logout/container";
import ForgotPasswordContainer from "./modules/auth/scenes/ForgotPassword/container";
import SignUpContainer from "./modules/auth/scenes/SignUp/container";
import MyAccountContainer from "./modules/MyAccount/container";
import ContactContainer from "./modules/contact/container";
import CartContainer from "./modules/store/scenes/Cart/container";
import CheckoutPageContainer from "modules/store/scenes/CheckoutPage/container/index";
import PlainInvoice from "modules/PlainInvoice/index";
import vintageHistory from "services/browser-history/index";
import {orderHistory} from "modules/MyAccount/scenes/OrderHistory/reducer";
import {productsList} from "modules/store/scenes/ProductsList/reducer";
import {routerMiddleware} from "react-router-redux";
import {headerCategoriesReducer} from "components/Header/components/HeaderCategoriesDropdown/reducer";
import AdvancedSearchContainer from "modules/store/scenes/AdvancedSearch/container/index";
import GiftCards from "modules/GiftCards";
import GuestOrderDetailsContainer from "modules/MyAccount/scenes/GuestOrderDetails/container/index";
import WishlistShare from "modules/MyAccount/scenes/WishlistShare/index";
import ResetPassword from "modules/auth/scenes/ResetPassword/index";
import OldProductsDetails from "utils/components/OldVintageProduct/OldProductDetails/index";
import ScrollToTop from "components/ScrollToTop/index";
import * as Sentry from '@sentry/browser';
import HeaderContainer from "components/Header/container";
import Footer from "components/Footer";
import ProductDetails from "modules/store/scenes/ProductDetails";
import ProductsList from "modules/store/scenes/ProductsList";
import ValidateEmailExpired from "modules/auth/scenes/ValidateEmailExpired"
import ValidateEmailContainer from "modules/auth/scenes/ValidateEmail/container"
import {QueryCache, ReactQueryCacheProvider} from 'react-query'
import Locations from "modules/Locations"

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
});

const combinedReducers = combineReducers({
  form: formReducer,
  toastr: toastrReducer,
  auth,
  account,
  contactUs,
  store,
  productsList,
  newsletter,
  orderHistory,
  headerCategoriesReducer
});

const reduxRouterMiddleware = routerMiddleware(vintageHistory);

const reduxStore = createStore(
    combinedReducers,
    applyMiddleware(
        thunkMiddleware,
        reduxRouterMiddleware
    )
);

const queryCache = new QueryCache()

ReactDOM.render(
    <Provider store={reduxStore}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <Router history={vintageHistory}>
          <div>
            <div className="vintagestock-app">
              <HeaderContainer/>
              <ScrollToTop/>
              <Switch>
                {/*Routes for Old Vintage Stock*/}
                <Route exact path="/products/:productSlug([a-zA-Z0-9.-]+.html)" component={OldProductsDetails}/>
                <Route exact path="/index.html" render={() => <Redirect to="/"/>}/>
                <Route exact path="/:productSlug([a-zA-Z0-9.-]+.html)" component={OldProductsDetails}/>
                <Route exact path="/products/:categorySlug/:productSlug([a-zA-Z0-9.-]+.html)"
                       component={OldProductsDetails}/>
                <Route exact path="/products/:category1Slug/:category2Slug/:productSlug([a-zA-Z0-9.-]+.html)"
                       component={OldProductsDetails}/>
                <Route exact
                       path="/products/:category1Slug/:category2Slug/:category3Slug/:productSlug([a-zA-Z0-9.-]+.html)"
                       component={OldProductsDetails}/>
                <Route exact path="/products.html" render={() => <Redirect to="/store/products"/>}/>
                <Route exact path="/contacts/" render={() => <Redirect to="/contact"/>}/>
                <Route exact path="/about-vintage-stock/" render={() => <Redirect to="/about"/>}/>
                <Route exact path="/now-hiring" render={() => <Redirect to="/about"/>}/>
                <Route exact path="/privacy-policy" render={() => <Redirect to="/about/privacy-policy"/>}/>
                <Route exact path="/terms-of-use" render={() => <Redirect to="/about/terms-of-use"/>}/>
                <Route exact path="/catalogsearch/advanced/"
                       render={() => <Redirect to="/store/advanced-search"/>}/>
                <Route exact path="/customer/account/login/" render={() => <Redirect to="/auth/login"/>}/>
                <Route exact path="/customer/account/forgotpassword/"
                       render={() => <Redirect to="/auth/forgot-password"/>}/>
                <Route exact path="/customer/account/create/" render={() => <Redirect to="/auth/signup"/>}/>
                <Route exact path="/customer/account/" render={() => <Redirect to="/my-account"/>}/>
                <Route exact path="/customer/account/edit/"
                       render={() => <Redirect to="/my-account/profile"/>}/>
                <Route exact path="/customer/account/edit/changepass/([0-9])/"
                       render={() => <Redirect to="/my-account/password"/>}/>
                <Route exact path="/sales/order/history/"
                       render={() => <Redirect to="/my-account/order-history"/>}/>
                <Route exact path="/customer/address/new/"
                       render={() => <Redirect to="/my-account/address-book"/>}/>
                <Route exact path="/wishlist/" render={() => <Redirect to="/my-account/wishlist"/>}/>
                <Route exact path="/checkout/cart/" render={() => <Redirect to="/store/cart"/>}/>
                <Route exact path="/checkout/onepage/" render={() => <Redirect to="/store/checkout"/>}/>

                {/*Routes for New Vintage Stock*/}
                <Route exact path="/" component={Home}/>
                <Route exact path="/gift-cards" component={GiftCards}/>
                <Route exact path="/store/products" component={ProductsList}/>
                <Route path="/store/products/:productSlug" component={ProductDetails}/>
                <Route path="/store/cart" component={CartContainer}/>
                <Route path="/store/order/:orderUUID" component={GuestOrderDetailsContainer}/>
                <Route exact path="/store/checkout" component={CheckoutPageContainer}/>
                <Route path="/store/checkout/success/:orderUUID" component={CheckoutSuccess}/>
                <Route path="/store/advanced-search" component={AdvancedSearchContainer}/>
                <Route exact path="/contact" component={ContactContainer}/>
                <Route path="/auth/signup" component={SignUpContainer}/>
                <Route path="/auth/login" component={LoginContainer}/>
                <Route path="/auth/logout" component={LogoutContainer}/>
                <Route path="/auth/forgot-password" component={ForgotPasswordContainer}/>
                <Route path="/auth/reset-password/:uuidToken" component={ResetPassword}/>
                <Route exact path="/auth/validate-email/expired" component={ValidateEmailExpired}/>
                <Route path="/auth/validate-email/:token" component={ValidateEmailContainer}/>
                {/*<Route exact path="/not-found" component={NotFound}/>*/}
                <Route exact path="/locations" component={Locations}/>
                <Route path="/my-account" component={MyAccountContainer}/>
                <Route path="/wishlist/:wishListId" component={WishlistShare}/>
                <Route path="/about" component={About}/>
                <Route path="/plain-invoices/:orderUUID" component={PlainInvoice}/>
                <Route component={NotFound}/>
              </Switch>
              <Footer/>
            </div>
            <ReduxToastr
                timeOut={3000}
                newestOnTop={false}
                position="bottom-right"
                icon={<span className="icon-circle-check"/>}
                closeButton={<span className="icon-circle-close"/>}
                progressBar={false}
                transitionIn="fadeIn"
                transitionOut="fadeOut"/>
          </div>
        </Router>
      </ReactQueryCacheProvider>
    </Provider>, document.getElementById('root'));
unregister();
