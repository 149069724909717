import React from "react"
import { LoadingCassette } from "@vintagestock/vintage-common-components"
import {useHistory, useLocation} from "react-router"
import QueryParams from "utils/QueryParams"
import {QUERY_PARAM_STORES} from "modules/store/scenes/ProductsList/constants/query-params"
import useLocations
  from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/components/SidebarAvailability/hooks/useLocations"
import LocationName
  from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/components/SidebarAvailability/components/LocationName"

export default function SelectedStores({emptyComponent}) {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new QueryParams(location.search)
  const {locations} = useLocations()
  const storesFromParams = queryParams.find(QUERY_PARAM_STORES)
  const selectedStores = storesFromParams
      ?
      Array.isArray(storesFromParams)
          ?
          storesFromParams
          :
          [storesFromParams]
      :
      []

  /**
   * Handle delete button click to remove store id from the query params
   * @param event
   */
  function handleDeleteBtnClick(event) {
    const {locationId} = event.target.dataset
    queryParams.update({
      [QUERY_PARAM_STORES]: selectedStores.filter(id => id !== locationId)
    })
    history.push({
      pathname: "/store/products",
      search: queryParams.asSearchString
    })
  }

  return (
      <div className="selected-stores">
        {
          selectedStores.length > 0
              ?
              locations
                  ?
                  <ul>
                    {
                      selectedStores.map(num =>
                          <li key={num}>
                            <LocationName id={num}/>
                            <button
                                data-location-id={num}
                                onClick={handleDeleteBtnClick}>
                              &times;
                            </button>
                          </li>
                      )
                    }
                  </ul>
                  :
                  <LoadingCassette/>
              :
              emptyComponent
        }
      </div>
  )
}