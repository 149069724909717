import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import "./styles/ProductListView.scss";
import AddToCartButtonContainer from "modules/store/scenes/ProductDetails/components/AddToCartButton/container/index";
import NotifyMeWhenAvailableButton from "utils/components/NotifyMeWhenAvailableButton/index";
import { ProductImage, ProductContext } from "@vintagestock/vintage-common-components";
import Moment from "react-moment";
import moment from "moment";
import ProductIcon from "modules/store/scenes/ProductsList/components/Product/components/ProductIcon";
import FindInStoreBtn from "modules/store/components/FindInStoreBtn";
import WishlistHeartBtnContainer from "modules/store/components/WishlistHeartBtn/container";
import ReadMoreReact from "read-more-react";
import {RADIO_ONLINE_NEW, RADIO_ONLINE_USED} from "modules/store/components/PriceTable/constants/radios"
import PriceTable from "modules/store/components/PriceTable"

export default function ProductListView({productUrl, onClick}) {
  const {product, condition, quantity, activeRadio} = useContext(ProductContext);

  return (
      <div className="product product--list-view">
        <div className="product-image">
          <div className="product-image__wishlist-btn">
            <WishlistHeartBtnContainer/>
          </div>
          <ProductIcon icon={product.icon}/>
          <ProductImage
              productName={product.name}
              productImageUrl={product.list_image_thumbnail}
              productUrl={productUrl}
              productIcon={product.icon}
              onClick={onClick}/>
        </div>
        <div className="product-content">
          <p className="product-content__name">{product.name}</p>
          {
            product.artist &&
            <p className="product-content__artist">{product.artist}</p>
          }
          {
            moment(product.release_date) > moment(new Date()) &&
            <p className="product-content__release-date">
              Release:&nbsp;
              <Moment format="MMM D, YYYY">
                {product.release_date}
              </Moment>
            </p>
          }
          <div style={{
            maxWidth: "240px"
          }}>
            <PriceTable/>
          </div>
          <hr/>
          {
            product.description
                ?
                <div className="product-content__description">
                  <ReadMoreReact
                      text={product.description}
                      readMoreText={"Read more"}/>
                </div>
                :
                <p>No description available</p>
          }
          <div className="form-group product-actions-buttons">
            {
              //Show Add to cart button
              product.in_stock || product.pickup_available
                  ?
                  <React.Fragment>
                    {
                      (activeRadio === RADIO_ONLINE_USED || activeRadio === RADIO_ONLINE_NEW)
                          ?
                          <div className="product-card-actions__actions">
                            <AddToCartButtonContainer
                                contentTypeId={product.content_type}
                                productId={product.id}
                                quantity={quantity}
                                productData={{
                                  sku: product.sku,
                                  name: product.name
                                }}
                                condition={condition}
                                hideIcon={product.is_pre_order}
                                customLabel={product.is_pre_order ? "PRE-ORDER" : "Add to cart"}
                                size="small"/>
                          </div>
                          :
                          <FindInStoreBtn
                              className="add-to-cart-button add-to-cart-button--style-button add-to-cart-button--size-small"/>
                    }
                  </React.Fragment>
                  :
                  <React.Fragment>
                    {
                      product.is_pre_order &&
                      //Show Notify me when available button
                      <NotifyMeWhenAvailableButton
                          condition={condition}
                          stockNew={product.total_stock_new}
                          stockUsed={product.total_stock_used}
                          productId={product.id}/>
                    }
                  </React.Fragment>
            }
          </div>
        </div>
      </div>
  );
}

ProductListView.propTypes = {
  productUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};
