/* global dataLayer */

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {NEW, USED} from "modules/store/scenes/ProductsList/components/Product/constants/conditions";
import {
  QUERY_PARAM_PICKUP_AVAILABLE,
  QUERY_PARAM_PRODUCT_CONDITION,
  QUERY_PARAM_STORES
} from "modules/store/scenes/ProductsList/constants/query-params";
import ProductGridView from "modules/store/scenes/ProductsList/components/Product/components/ProductGridView/index";
import ProductListView from "modules/store/scenes/ProductsList/components/Product/components/ProductListView/index";
import {parseCondition} from "utils/products";
import { ProductContext } from '@vintagestock/vintage-common-components';
import {
  RADIO_OFFLINE_NEW,
  RADIO_OFFLINE_USED,
  RADIO_ONLINE_NEW,
  RADIO_ONLINE_USED
} from "modules/store/components/PriceTable/constants/radios"
import useQueryParams from "hooks/useQueryParams"
import queryString from "query-string"

export default function Product({product, asList}) {
  const queryParams = useQueryParams()
  const [quantity, setQuantity] = useState(1);
  const [activeRadio, setActiveRadio] = useState(
      parseCondition(queryParams.find(QUERY_PARAM_PRODUCT_CONDITION)) === NEW
          ?
          product.total_stock_new > 0 && queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE) === undefined
              ?
              RADIO_ONLINE_NEW
              :
              RADIO_OFFLINE_NEW
          :
          parseCondition(queryParams.find(QUERY_PARAM_PRODUCT_CONDITION)) === USED
              ?
              product.total_stock_used > 0 && queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE) === undefined
                  ?
                  RADIO_ONLINE_USED
                  :
                  RADIO_OFFLINE_USED
              :
              product.total_stock_used > 0 && queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE) === undefined
                  ?
                  RADIO_ONLINE_USED
                  :
                  product.is_pre_order || (product.total_stock_new > 0 && queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE) === undefined)
                      ?
                      RADIO_ONLINE_NEW
                      :
                      product.pickup_available_used
                          ?
                          RADIO_OFFLINE_USED
                          :
                          RADIO_OFFLINE_NEW
  );
  const [condition, setCondition] = useState((activeRadio === RADIO_ONLINE_USED || activeRadio === RADIO_OFFLINE_USED) ? USED : NEW);

  useEffect(() => {
    setCondition((activeRadio === RADIO_ONLINE_USED || activeRadio === RADIO_OFFLINE_USED) ? USED : NEW)
  }, [activeRadio])

  /**
   * Parses the product status from query params and returns it or null by default.
   * @returns {Number} productStatus
   */
  function productStatusQueryParam() {
    const conditionQueryParam = queryParams.find(QUERY_PARAM_PRODUCT_CONDITION);
    return conditionQueryParam ? parseCondition(conditionQueryParam) : null;
  }

  /**
   * Determine the right url to redirect the user when a product is clicked.
   * This is based on "product-status" query params, which determines the product condition to give priority to.
   * @returns {Object}
   */
  function productUrl() {
    let params = {}

    const productStatus = productStatusQueryParam();
    if (productStatus)
      params[QUERY_PARAM_PRODUCT_CONDITION] = productStatus

    const store = queryParams.find(QUERY_PARAM_STORES)
    if (store)
      params[QUERY_PARAM_STORES] = store

    const pickupAvailable = queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE)
    if (pickupAvailable)
      params[QUERY_PARAM_PICKUP_AVAILABLE] = pickupAvailable

    return {
      pathname: `/store/products/${product.slug}`,
      search: `?${queryString.stringify(params)}`
    };
  }

  /**
   * Handles clicks on the product.
   */
  function handleProductClick() {
    dataLayer.push({
      "event": "productClick",
      "ecommerce": {
        "currencyCode": "USD",
        "click": {
          "actionField": {"list": "Products"},
          "products": [{
            "id": product.sku,
            "name": product.name
          }]
        }
      }
    });
  }

  return (
      <ProductContext.Provider value={{
        product,
        condition,
        setCondition,
        quantity,
        setQuantity,
        activeRadio,
        setActiveRadio
      }}>
        {
          asList
              ?
              <ProductListView
                  productUrl={productUrl()}
                  onClick={handleProductClick}/>
              :
              <ProductGridView
                  productUrl={productUrl()}
                  onClick={handleProductClick}/>
        }
      </ProductContext.Provider>
  );
}

Product.propTypes = {
  product: PropTypes.object.isRequired,
  asList: PropTypes.bool
};

Product.defaultProps = {
  asList: false
};
