import React, {useEffect, useState} from "react";
import vintageAxios from "services/api/index"
import mail from "./img/mail.svg"
import "./styles/VerifyEmailAlert.scss"
import ValidateEmailButton from "modules/auth/scenes/ValidateEmailExpired/components/ValidateEmailButton"

export default function VerifyEmailAlert() {
  const [customer, setCustomer] = useState(null)

  useEffect(() => {
    if (!customer)
      fetchCustomer()
  }, [customer])

  function fetchCustomer() {
    const promise = vintageAxios.get("/management/customers/");
    promise.then(response => {
      setCustomer(response.data)
    });
    promise.catch(error => {

    });
  }

  if (customer && customer.confirmed_email === false)
    return (
        <div className="verify-email-alert">
          <img src={mail} alt=""/>
          <span>Validate your email and get orders made as a guest. We’ll send an email to make validation.</span>
          <ValidateEmailButton
              label="Validate my email"
              loadingLabel="Sending email..."
              successLabel="Email sent!"/>
        </div>
    )

  return null;
}