import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import StoreAccordion
  from "modules/store/scenes/ProductDetails/components/SingleProductStoreAvailability/components/StoreAvailabilityModal/components/SearchBasedAvailability/components/StoreAccordion"
import useQueryParams from "hooks/useQueryParams"
import {QUERY_PARAM_STORES} from "modules/store/scenes/ProductsList/constants/query-params"
import arrayify from "services/browser-history/arrayify"
import useLocations
  from "modules/store/scenes/ProductsList/components/ProductsListSidebarV2/components/SidebarAvailability/hooks/useLocations"
import StoreInfoCard
  from "modules/store/scenes/ProductDetails/components/SingleProductStoreAvailability/components/StoreAvailabilityModal/components/StoreInfoCard"
import "./styles/SearchBasedAvailability.scss"
import { LoadingCassette } from "@vintagestock/vintage-common-components"

export default function SearchBasedAvailability({stores, locations: locationsFromSearch, isLoading}) {
  const queryParams = useQueryParams()
  const {locations} = useLocations()
  // I'm converting by default into an array because currently there's only one result
  // as the client asked to change it so the user can select only 1
  // TODO SINGULAR STORES Fix this problem
  let storesFromParams = queryParams.find(QUERY_PARAM_STORES) ? [queryParams.find(QUERY_PARAM_STORES)] : []
  storesFromParams = storesFromParams ? arrayify(storesFromParams).map(store => parseInt(store)) : []
  const intersection = _.intersection(storesFromParams, stores)
  const difference = _.difference(stores, storesFromParams)

  return (
      <div className="search-based-availability">
        {
          intersection.length > 0 &&
          <>
            <p className="subtitle"><strong>Based on your search, available in:</strong></p>
            {
              intersection.map(store => (
                  <StoreAccordion
                      key={store}
                      store={_.find(locations, {id: store})}/>
              ))
            }
          </>
        }
        {
          (locationsFromSearch && locationsFromSearch.length > 0 && difference.length > 0)
              ?
              <div className="search-based-availability__also-in">
                <p className="subtitle">
                  {
                    intersection.length > 0
                        ?
                        <strong>Also available in:</strong>
                        :
                        <strong>Currently available in:</strong>
                  }
                </p>
                {
                  locationsFromSearch.map(location => {
                    // Exclude the one in the query params
                    if (storesFromParams && location.id === parseInt(storesFromParams))
                      return null

                    return (
                        <StoreInfoCard
                            key={location.id}
                            store={location}/>
                    )
                  })
                }
              </div>
              :
              (
                  isLoading
                      ?
                      <div>
                        <LoadingCassette/>
                      </div>
                      :
                      <div>
                        <p>We didn't find any stores matching your search criteria.</p>
                      </div>
              )

        }
      </div>
  )
}

SearchBasedAvailability.propTypes = {
  stores: PropTypes.array,
  isLoading: PropTypes.bool
}