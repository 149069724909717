import React, {useCallback, useEffect, useState} from 'react'
import {Link, useHistory} from "react-router-dom"
import classNames from "classnames/bind"
import vintageAxios from "services/api/index"
import "./styles/HeaderSearch.scss"
import _ from "lodash"
import {
  KEY_CODE_ARROW_DOWN,
  KEY_CODE_ARROW_UP,
  KEY_CODE_ENTER,
  KEY_CODE_ESCAPE
} from "components/Header/components/HeaderSearchV2/constants/key-codes"
import {
  QUERY_PARAM_CATEGORIES,
  QUERY_PARAM_IN_STOCK,
  QUERY_PARAM_PICKUP_AVAILABLE,
  QUERY_PARAM_SEARCH,
  QUERY_PARAM_STORES
} from "modules/store/scenes/ProductsList/constants/query-params"
import useQueryParams from "hooks/useQueryParams"
import onClickOutside from "react-onclickoutside"
import CategoriesSelect from "components/Header/components/HeaderSearchV2/components/CategoriesSelect"

export function HeaderSearchV2() {
  const queryParams = useQueryParams()
  const history = useHistory()
  const [query, setQuery] = useState(queryParams.find(QUERY_PARAM_SEARCH) || '')
  const [suggestionsBoxIsOpen, setSuggestionsBoxIsOpen] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedSuggestionData, setSelectedSuggestionData] = useState(null)
  // TODO why do I have a zero here? Should be something descriptive
  // TODO selected category id and selected category type (type for autocomplete, id for query params)
  const [selectedCategory, setSelectedCategory] = useState(0)
  const [selectedProductType, setSelectedProductType] = useState(0)
  const debouncedFetchSuggestions = useCallback(_.debounce(fetchSuggestions, 500), [query])
  const searchQueryFromParams = queryParams.find(QUERY_PARAM_SEARCH)

  /**
   * Reset the search query whenever the url changes and it's not present in the query params
   */
  useEffect(() => {
    if (searchQueryFromParams === undefined)
      setQuery("")
  }, [searchQueryFromParams])

  /**
   * Call debounced function when the query changes
   */
  useEffect(() => {
    debouncedFetchSuggestions()
    // Whenever the query changes, unselect to prevent going to a previously highlighted result
    if (selectedSuggestionData)
      setSelectedSuggestionData(null)

    return debouncedFetchSuggestions.cancel
  }, [query])

  /**
   * Fetch suggestions when the category changes
   */
  useEffect(() => {
    fetchSuggestions()
  }, [selectedCategory])

  /**
   * Fetch suggestions from the API
   * @returns {AxiosPromise}
   * @private
   */
  function _fetchSuggestions() {
    let params = {}
    const stores = queryParams.find(QUERY_PARAM_STORES)
    const pickupAvailable = queryParams.find(QUERY_PARAM_PICKUP_AVAILABLE)
    const inStock = queryParams.find(QUERY_PARAM_IN_STOCK)
    params.search = query
    if (selectedCategory) params.type = selectedProductType
    if (stores) params[QUERY_PARAM_STORES] = stores
    if (pickupAvailable) params[QUERY_PARAM_PICKUP_AVAILABLE] = pickupAvailable
    if (inStock) params[QUERY_PARAM_IN_STOCK] = inStock
    const promise = vintageAxios.get(`/misc/autocomplete/products/`, {
      params
    })
    promise.then(response => {
      setSuggestions(response.data)
      setLoading(false)
    })
    return promise
  }

  /**
   * Checks if there is a query to fetch results from the API.
   */
  function fetchSuggestions() {
    if (query.length > 0) {
      return _fetchSuggestions();
    } else {
      setSuggestions([])
      setLoading(false)
      return Promise.resolve([]);
    }
  }

  /**
   * Handle changes on the search input
   * @param {Object} event
   */
  function handleInputChange(event) {
    const value = event.target.value
    setQuery(value)
    setLoading(true)
    setSuggestionsBoxIsOpen(true)
  }

  /**
   * Handles focus event on the search input.
   */
  function handleInputFocus() {
    setSuggestionsBoxIsOpen(true)
  }

  /**
   * Deselects the selected suggestion in the list.
   */
  function deselectSuggestion() {
    setSelectedSuggestionData(null)
  }

  /**
   * Selects a suggestion in the component state.
   * @param {Object} suggestion
   * @param {Number} index
   */
  function selectSuggestion(suggestion, index) {
    setSelectedSuggestionData({
      id: suggestion.id,
      slug: suggestion.slug,
      index: index  // store the index in the array to easily identify in the future
    })
  }

  /**
   * Select a suggestion at desired index (in the suggestions array).
   * @param {Number} index
   * @param {Boolean} shouldScroll
   */
  function selectSuggestionAtIndex({index, shouldScroll = true}) {
    const suggestion = suggestions[index];
    if (suggestion) {
      selectSuggestion(suggestion, index);
      if (shouldScroll)
        document.getElementsByClassName('vintage-autocomplete__box')[0].scrollTop =
            document.getElementsByClassName('suggestion')[0].offsetHeight * (index - 2);  // show the current and the previous 2
    }
  }

  /**
   * Selects next suggestion in the list, fallback to first if nothing currently selected.
   */
  function selectNextSuggestion() {
    if (selectedSuggestionData) {
      let nextIndex = selectedSuggestionData.index + 1;
      if (nextIndex <= suggestions.length - 1) {  // is not the last in the array
        const suggestionAtIndex = suggestions[nextIndex];
        // TODO what if I have 2 headers together?
        if (suggestionAtIndex.id === null)  // Suggestions with null id are headers
          nextIndex += 1;
        selectSuggestionAtIndex({index: nextIndex});
      }
    } else {
      selectSuggestionAtIndex({index: 1});  // 1 because at 0 we'll always have a header
    }
  }

  /**
   * Selects previous suggestion in the list, fallback to first if nothing currently selected.
   */
  function selectPreviousSuggestion() {
    if (selectedSuggestionData) {
      let previousIndex = selectedSuggestionData.index - 1;
      if (previousIndex >= 1) {  // 1 because at 0 we'll always have a header
        const suggestionAtIndex = suggestions[previousIndex];
        // TODO what if I have 2 headers together?
        if (suggestionAtIndex.id === null)  // Suggestions with null id are headers
          previousIndex -= 1;
        selectSuggestionAtIndex({index: previousIndex});
      } else  // the index is lower than zero, clear the selection
        deselectSuggestion();
    } else {
      selectSuggestionAtIndex({index: 1});  // 1 because at 0 we'll always have a header
    }
  }

  /**
   * Just prevent default behaviour when submitting the form
   * @param event {Object}
   */
  function handleSubmit(event) {
    event.preventDefault()
  }

  /**
   * Handles key down events on search input.
   * @param {Object} event
   */
  function handleInputKeyDown(event) {
    if (event.keyCode === KEY_CODE_ESCAPE) {
      event.preventDefault()
      event.target.blur()
      setSuggestionsBoxIsOpen(false)
      setSelectedSuggestionData(null)
    } else if (event.keyCode === KEY_CODE_ARROW_UP) {
      event.preventDefault()
      if (suggestions.length > 0)
        selectPreviousSuggestion()
    } else if (event.keyCode === KEY_CODE_ARROW_DOWN) {
      if (suggestions.length > 0)
        selectNextSuggestion()
    } else if (event.keyCode === KEY_CODE_ENTER) {
      event.preventDefault()  // prevent normal form submit
      if (selectedSuggestionData) {
        history.push({
          pathname: `/store/products/${selectedSuggestionData.slug}`
        })
        setQuery("")
        event.target.blur()
      } else {
        queryParams.update({
          [QUERY_PARAM_SEARCH]: query
        })
        // TODO don't use numbers to compare here
        if (selectedCategory > 0)
          queryParams.update({
            [QUERY_PARAM_CATEGORIES]: selectedCategory
          })
        history.push({
          pathname: '/store/products',
          search: queryParams.asSearchString
        });
      }
      setSuggestionsBoxIsOpen(false)
      setSelectedSuggestionData(null)
    }
  }

  /**
   * Handles click on the suggestions in the list
   */
  function handleSuggestionClick() {
    setSuggestionsBoxIsOpen(false)
    setQuery("")
    setSelectedSuggestionData(null)
  }

  /**
   * Handles mouse enter event on suggestions in the list
   * @param {Object} event
   */
  function handleSuggestionMouseEnter(event) {
    const {suggestionIndex} = event.target.dataset
    selectSuggestionAtIndex({index: parseInt(suggestionIndex), shouldScroll: false})  // Otherwise will be scrolling on mouse moves
  }

  /**
   * Handles changes on the CategoriesSelect component
   * @param category {Object}
   */
  function handleCategoriesSelectChange(category) {
    setSelectedCategory(category.id)
    setSelectedProductType(category.product_type)
  }

  /**
   * Click outside box
   * @param event {Object}
   */
  HeaderSearchV2.handleClickOutside = (event) => {
    setSuggestionsBoxIsOpen(false)
    setSelectedSuggestionData(null)
  }

  const SuggestionsBox = () => {
    if (loading)
      return (
          <p className="text-center">
            <i className="fa fa-spinner fa-spin"/>&nbsp;Looking for results...
          </p>
      )

    if (query.length === 0)
      return (
          <p className="text-center">
            Start typing to search...
          </p>
      )

    if (query.length > 0 && suggestions.length === 0)
      return (
          <p className="text-center">
            We did not found anything related with '{query}'
          </p>
      )

    return (
        <>
          <div className="vintage-autocomplete__suggestions">
            {
              suggestions.map((suggestion, index) => {
                    // Render the headers...
                    if (suggestion.id === null)
                      return (
                          <div className="suggestion suggestion--header">
                            <span>{suggestion.name}</span>
                          </div>
                      )

                    return (
                        <Link
                            key={suggestion.id}
                            id={`suggestion${suggestion.id}`}
                            className={classNames({
                              "suggestion": true,
                              "suggestion--focused": (
                                  selectedSuggestionData &&  // There is a selected suggestion
                                  selectedSuggestionData.id === suggestion.id
                              ),
                              "suggestion--header": suggestion.id === null
                            })}
                            to={`/store/products/${suggestion.slug}`}
                            data-suggestion-index={index}
                            data-suggestion-slug={suggestion.slug}
                            onClick={handleSuggestionClick}
                            onMouseEnter={handleSuggestionMouseEnter}>
                          <span>{suggestion.name}</span>
                        </Link>
                    )
                  }
              )
            }
          </div>
          <div className="link-advanced-search">
            <Link to="/store/advanced-search" onClick={() => {
              setSuggestionsBoxIsOpen(false)
              setSelectedSuggestionData(null)
            }}>
              <i className="fa fa-filter"/>
              &nbsp; Advanced Search</Link>
          </div>
        </>
    )
  }

  return (
      <form
          onSubmit={handleSubmit}
          className="header-search-form form-inline">
        <div className="form-group">
          <div className="vintage-autocomplete">
            <div
                className="form-group vintage-autocomplete__select-wrapper vintage-autocomplete__select-wrapper-noimage">
              <CategoriesSelect
                  onChange={handleCategoriesSelectChange}/>
            </div>
            <div className="form-group has-feedback">
              <button type="submit" className="icon icon-loup"/>
              <input
                  type="text"
                  className="form-control header-search-input"
                  value={query}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onKeyDown={handleInputKeyDown}
                  placeholder="I'm looking for..."/>
              <Link to={{
                pathname: "/store/advanced-search",
                // search: `?${queryString.stringify(clearQueryParamFromUrl())}`
              }}>
                <i className="fa fa-filter form-control-feedback fa-padding"/>
                
              </Link>
            </div>
            <div
                className={classNames({
                  "vintage-autocomplete__box": true,
                  "vintage-autocomplete__box--open": suggestionsBoxIsOpen
                })}>
              <SuggestionsBox/>
            </div>
          </div>
        </div>
      </form>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => HeaderSearchV2.handleClickOutside
}

export default onClickOutside(HeaderSearchV2, clickOutsideConfig)
