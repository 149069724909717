import React, {useCallback, useEffect, useState} from "react";
import {Redirect, useHistory, useLocation} from "react-router"
import { LoadingCassette } from "@vintagestock/vintage-common-components"
import vintageAxios from "services/api";
import success from "./img/success.svg"
import check from "./img/check.svg"
import OrderContainer from "modules/MyAccount/scenes/OrderHistory/components/Order/container"
import "./styles/ValidateEmail.scss"

export default function ValidateEmail({match, auth: {isAuthenticated}, orderHistory: {modals}, closeModal}) {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(false)
  const [orders, setOrders] = useState([])
  const [customer, setCustomer] = useState(null)
  const {token} = match.params

  /**
   * Validate the email through the API
   */
  const validateEmailToken = useCallback(() => {
    setLoading(true)
    const promise = vintageAxios.post("/auth/validate-email-token/", {
      token
    });
    // const promise = fakePromise()
    promise.then(response => {
      if (response.data.success === true) {
        setValidated(true)
        setOrders(response.data.orders)
      }
      setLoading(false)
    });
    promise.catch(error => {
      setLoading(false)
      history.push("/auth/validate-email/expired")
    });
  }, [history, token])

  useEffect(() => {
    if (isAuthenticated && !customer)
      fetchCustomer()
  }, [customer, isAuthenticated])

  /**
   * Check if not already validating and run the validate function
   */
  useEffect(() => {
    if (isAuthenticated && !validated && !loading)
      validateEmailToken()
  }, [validated, isAuthenticated, loading, validateEmailToken])

  function fetchCustomer() {
    const promise = vintageAxios.get("/management/customers/");
    promise.then(response => {
      setCustomer(response.data)
    });
    promise.catch(error => {

    });
  }

  if (!isAuthenticated)
    return <Redirect to={{
      pathname: "/auth/login",
      search: `?from=${location.pathname}`
    }}/>

  return (
      <div>
        {
          loading
              ?
              <LoadingCassette/>
              :
              <React.Fragment>
                <div className="container validation-success">
                  <div className="text-center">
                    <img src={success} alt=""/>
                    <h1>Email Validated Successfully</h1>
                    <div className="email-address">
                      <img src={check} alt=""/>
                      <span>{customer && customer.user.email}</span>
                    </div>
                    <div className="subtitle">
                      <p>These are the orders you made as a guest:</p>
                    </div>
                  </div>
                  <div className="orders">
                    {
                      orders.map(order =>
                          <OrderContainer key={order.id} order={order}/>
                      )
                    }
                  </div>
                </div>
                {
                  modals.map((modal, index) => {
                    const ComponentToRender = modal.component;
                    return (
                        <span
                            key={`orderModal${index}`}>
                    {
                      modal.show && (
                          <ComponentToRender
                              show={modal.show}
                              onClose={() => closeModal(modal.name)}
                              {...modal.props}/>
                      )
                    }
                  </span>
                    );
                  })
                }
              </React.Fragment>
        }
      </div>
  )
}